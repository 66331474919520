const generateRootMeta = ({
	path,
	origin,
}: {
	path: string
	origin: string
}) => {
	const originDomain = origin
	const pathname = path
	// const { contractAddress, tokenId, cid } = params;
	let ogUrl = originDomain

	if (pathname !== '/') {
		ogUrl += pathname
	}

	const titleElements = [
		{ title: import.meta.env.VITE_APP_NAME },
		{ property: 'og:title', content: import.meta.env.VITE_APP_NAME },
		{ name: 'twitter:title', content: import.meta.env.VITE_APP_NAME },
	]

	const descriptionElements = [
		{
			name: 'description',
			content: import.meta.env.VITE_APP_DESCRIPTION,
		},
		{
			name: 'twitter:description',
			content: import.meta.env.VITE_APP_DESCRIPTION,
		},
		{
			property: 'og:description',
			content: import.meta.env.VITE_APP_DESCRIPTION,
		},
	]

	const imageElements = [
		{
			property: 'og:image',
			content: `${originDomain}/lore-og-image.png`,
		},
		{
			name: 'twitter:image',
			content: `${originDomain}/lore-og-image.png`,
		},
		{
			name: 'twitter:card',
			content: 'summary_large_image',
		},
		// { property: 'og:image:width', content: '1200px' },
		// { property: 'og:image:height', content: '630px' }
	]

	return [
		...titleElements,
		...descriptionElements,
		...imageElements,
		{ name: 'twitter:site', content: '@atomicform' },
		{ name: 'twitter:creator', content: '@atomicform' },
		{
			property: 'og:url',
			content: ogUrl,
		},
		{ property: 'og:type', content: 'website' },
		{ property: 'og:site_name', content: 'Atomic Lore' },
		{ property: 'og:locale', content: 'en_US' },
		{ property: 'fb:app_id', content: import.meta.env.VITE_META_APP_ID },
	]
}

export default generateRootMeta
